import MainLayout from '../layout/MainLayout';
import React, { useEffect, useState } from 'react';
import { useCustomer } from '../../hooks/use-customer';
import Section from '../Section';
import AccountLayout from './AccountLayout';
import Breadcrumb from '../Breadcrumb';
import { Table, Typography } from 'antd';
import { CustomerTag } from '../../types/Customer';
import { DateTime } from 'luxon';
import BasePagination from '../pagination/BasePagination';
import { chunk } from 'lodash';
import AccountMultipleMinusIcon from '../../icons/account-multiple-minus';

const { Text } = Typography;

const PAGE_SIZE = 10;
const CompanyPage = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CustomerTag>();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    actions: { getCompany },
  } = useCustomer();
  const fetchCompany = async () => {
    try {
      setLoading(true);
      const res = await getCompany();
      setCompany(res);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: '/account',
          },
          {
            title: 'สมาชิกในบริษัท',
            link: '/account/company',
          },
        ]}
        title="สมาชิกในบริษัท"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
            <div className="flex  mb-6">
              <div className="flex flex-col">
                <Text className="!text-light-secondary mb-1">บริษัท</Text>
                <Text className="!text-light-title !text-2xl !font-semibold">
                  {company?.name || '-'}
                </Text>
              </div>
              <div className="ml-8 flex flex-col">
                <Text className="!text-light-secondary mb-1">จำนวนสมาชิก</Text>
                <Text className="!text-light-title !text-2xl !font-semibold">
                  {company?.customers.length || '-'}
                </Text>
              </div>
            </div>
            {!loading && company ? (
              <>
                <Table
                  loading={loading}
                  scroll={{ x: true }}
                  dataSource={
                    company?.customers
                      ? chunk(company?.customers, PAGE_SIZE)[currentPage - 1]
                      : []
                  }
                  pagination={false}
                  columns={[
                    {
                      className: '!pl-0',
                      title: 'ชื่อ',
                      dataIndex: 'first_name',
                      key: 'first_name',
                    },
                    {
                      title: 'นามสกุล',
                      dataIndex: 'last_name',
                      key: 'last_name',
                    },
                    {
                      title: 'อีเมล',
                      dataIndex: 'email',
                      key: 'email',
                    },
                    {
                      title: 'เบอร์ติดต่อ',
                      dataIndex: 'phone',
                      key: 'phone',
                    },
                    {
                      title: 'สร้างเมื่อ',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      render: (value) =>
                        DateTime.fromISO(value).toFormat('d LLL yy'),
                    },
                  ]}
                />
                <BasePagination
                  current={currentPage}
                  total={company?.customers.length || 0}
                  pageSize={PAGE_SIZE}
                  onChange={(page) => {
                    setCurrentPage(page);
                  }}
                />
              </>
            ) : (
              <EmptyMember />
            )}
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

const EmptyMember = () => {
  return (
    <div className="w-full flex items-center flex-col p-6">
      <AccountMultipleMinusIcon />
      <span className="text-light-secondary mt-[26px]">ไม่พบบริษัทของคุณ</span>
    </div>
  );
};

export default CompanyPage;
