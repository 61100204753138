import React from 'react';

export const AccountMultipleMinusIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_130_10591)">
      <path
        d="M38 34V38H14V34C14 34 14 26 26 26C38 26 38 34 38 34ZM32 16C32 14.8133 31.6481 13.6533 30.9888 12.6666C30.3295 11.6799 29.3925 10.9109 28.2961 10.4567C27.1997 10.0026 25.9933 9.88378 24.8295 10.1153C23.6656 10.3468 22.5965 10.9182 21.7574 11.7574C20.9182 12.5965 20.3468 13.6656 20.1153 14.8295C19.8838 15.9933 20.0026 17.1997 20.4567 18.2961C20.9108 19.3925 21.6799 20.3295 22.6666 20.9888C23.6533 21.6481 24.8133 22 26 22C27.5913 22 29.1174 21.3679 30.2426 20.2426C31.3679 19.1174 32 17.5913 32 16ZM38.4 26.12C39.4933 27.1286 40.3747 28.3449 40.9928 29.6978C41.6109 31.0508 41.9533 32.5133 42 34V38H48V34C48 34 48 27.1 38.4 26.12ZM36 10C35.3957 10.0004 34.7952 10.0948 34.22 10.28C35.3901 11.9579 36.0175 13.9544 36.0175 16C36.0175 18.0456 35.3901 20.0421 34.22 21.72C34.7952 21.9052 35.3957 21.9996 36 22C37.5913 22 39.1174 21.3679 40.2426 20.2426C41.3679 19.1174 42 17.5913 42 16C42 14.4087 41.3679 12.8826 40.2426 11.7574C39.1174 10.6321 37.5913 10 36 10ZM16 20H0V24H16V20Z"
        fill="url(#paint0_linear_130_10591)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_130_10591"
        x1="1.82923e-06"
        y1="9.66223"
        x2="10.7823"
        y2="42.6115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0F172A" stop-opacity="0.06" />
        <stop offset="1" stop-color="#0F172A" stop-opacity="0.15" />
      </linearGradient>
      <clipPath id="clip0_130_10591">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AccountMultipleMinusIcon;
